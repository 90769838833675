<template>
  <nav>
    <v-expansion-panels
        v-model="menuMobile"
        class="bg-primary"
        multiple
        style="z-index: 906;"
        variant="accordion">
      <v-expansion-panel
          v-for="item in menuItens"
          class="bg-primary"
          expand>
        <v-expansion-panel-title class="pa-6">
          {{ item.titulo }}
        </v-expansion-panel-title>
        <v-expansion-panel-text ripple>
          <div class="bg-primary">
            <v-list
                class="bg-primary-darken-2 lista-menu-mobile"
                variant="text">
              <v-list-item
                  v-for="(navItem, index) in item.itens"
                  :key="navItem.title"
                  :href="navItem.url"
                  :target="navItem.url.includes('https://') ? '_blank' : ''"
                  append-icon="mdi-chevron-right"
                  class="mx-2"
                  style="color: white !important; text-decoration: none !important;">
                <v-list-item-title
                    class="py-3">
                  {{ navItem.titulo }}
                </v-list-item-title>
                <v-divider
                    v-if="index !== item.itens.length - 1"
                    thickness="1"></v-divider>
              </v-list-item>
            </v-list>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </nav>
</template>
<script lang="ts">
import type {OpcaoMenu} from "~/pages/home-cliente.vue";
import type {PropType} from "vue";

export default defineComponent({
  props: {
    menuItens: {
      type: Array as PropType<OpcaoMenu[]>,
      required: true,
    }
  },
  data() {
    return {
      menuMobile: [],
    }
  },
})
</script>
<style scoped>
.lista-menu-mobile {
  padding: 0 !important;
  border-radius: 5px;
}

a:-webkit-any-link {
  color: white !important;
  transition: color linear 0.2s !important;
  text-decoration: none !important;
}

a:-webkit-any-link:hover {
  color: rgb(var(--v-theme-secondary)) !important;
  text-decoration: underline !important;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline: 0 !important;
}

.v-expansion-panel {
  border-radius: 0 !important;
}

.v-btn--size-default {
  font-size: 12px !important;
  padding: 0;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 8px 15px 16px !important;
}
</style>
